<template>
    <div class="w-100">
        <div class="p-3">
            <h1 style="opacity: 0.7; font-size: 1.25rem;" class="generic-typography">{{ $gettext("Support") }}</h1>
        </div>
        <div>
            <b-form class="customized-form w-100">
                <ValidationObserver ref="support_form" tag="div">

                    <div class="col-md-6">
                        <ValidationProvider rules="required" name="fullName" v-slot="{ errors, classes }">
                            <b-form-group class="zc-form-label" :label='$gettext(" Full_name")' label-for="FullName">
                                <b-form-input :class="classes" id="FullName" v-model="fullName" size="sm" type="text"
                                    :placeholder='$gettext(" Full_name")'></b-form-input>
                            </b-form-group>
                            <div class="error-msg" :class="classes">
                                <span>{{ errors[0] ? 'Field Required' : errors[0] }}</span>
                            </div>
                        </ValidationProvider>
                    </div>
                    <div class="col-md-6">
                        <ValidationProvider :rules="mobile !== '' ? '' : 'required|Email'" name="email"
                            v-slot="{ errors, classes }">
                            <b-form-group class="zc-form-label" :label='$gettext(" Email")' label-for="email">
                                <b-form-input :class="classes" id="email" v-model="email" size="sm" type="text"
                                    :placeholder='$gettext(" Email")'></b-form-input>
                            </b-form-group>
                            <div class="error-msg" :class="classes">
                                <span>{{ errors[0] ? 'Field Required' : errors[0] }}</span>
                            </div>
                        </ValidationProvider>
                    </div>
                    <div class="col-md-6">
                        <ValidationProvider :rules="email !== '' ? '' : 'required|Phone'" name="mobile"
                            v-slot="{ errors, classes }">
                            <b-form-group class="zc-form-label" :label='$gettext(" Mobile_number")' label-for="mobile">
                                <b-form-input :class="classes" id="mobile" v-model="mobile" size="sm" type="text"
                                    :placeholder='$gettext("Input_your_mobile_number")'></b-form-input>
                            </b-form-group>
                            <div class="error-msg" :class="classes">
                                <span>{{ errors[0] ? 'Field Required' : errors[0] }}</span>
                            </div>
                        </ValidationProvider>
                    </div>
                    <div class="col-md-6">
                        <ValidationProvider rules="required" name="message" v-slot="{ errors, classes }">
                            <b-form-group class="zc-form-label" :label='$gettext(" Type_your_message")' label-for="message">
                                <b-form-textarea :class="classes" style="overflow:auto;" id="message" v-model="message"
                                    :placeholder='$gettext("Enter_something")' rows="3" max-rows="6"></b-form-textarea>
                            </b-form-group>
                            <div class="error-msg" :class="classes">
                                <span>{{ errors[0] ? 'Field Required' : errors[0] }}</span>
                            </div>
                        </ValidationProvider>
                    </div>

                    <div class="col-12 mb-2 btn-flex-fd-row-jc-sb">
                        <!-- <span @click="goBack" class="go-back">Back</span> -->
                        <b-button @click.prevent.stop="ValidateInput" variant="primary" size="sm">{{ $gettext("Submit")
                        }}</b-button>

                    </div>
                </ValidationObserver>
            </b-form>
        </div>
    </div>
</template>

<script>
import CandidateService from "@/apiServices/CandidateService";

export default {
    name: "Support",
    data() {
        return {
            fullName: "",
            email: "",
            mobile: '',
            message: ''
        }
    },
    methods: {

        ValidateInput() {
            return this.$refs.support_form.validate().then((result) => {
                if (result) {
                    const payload = {
                        role_id: 3,
                        name: this.fullName,
                        email: this.email,
                        phone: this.mobile,
                        message: this.message,
                    }
                    console.log('payload', payload);
                    let loader = this.$loading.show();
                    CandidateService.Support(payload)
                        .then((res) => {

                            console.log("sopport::res", res);
                            this.$toasted.show(`${res.data.message}`, {
                                duration: 6000,
                                type: "success",
                                icon: "check",
                            });
                            loader.hide()
                        })
                        .catch((error) => {
                            loader.hide()
                            console.log(error);
                        })
                }
            })
        },
    },
    beforeMount() {
        const userData = this.$store.getters.getLoggedUser.userData
        this.fullName = this.$store.getters.getLoggedUser.userData.user_username
        this.email = this.$store.getters.getLoggedUser.userData.user_email
        this.mobile = this.$store.getters.getLoggedUser.userData.user_mobile_number
        console.log('userData', userData);
    },
}
</script>

<style></style>